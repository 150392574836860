
// Vue
import {Options, Vue} from "vue-class-component";

// Vendors
import Vue3Html2pdf from "vue3-html2pdf";
import moment from "moment/moment";

// Store
import store from "@/store/index";

// Models
import {AppOutgoingInvoiceIndex} from "@/models/app/outgoing-invoice";
import {AppPartnerIndex} from "@/models/app/partner";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElTable,
  ElTableColumn,
  ElButton,
} from "element-plus";
import {
  Printer,
} from "@element-plus/icons-vue";
import AppAdministratorReportsOutgoingInvoicesListFormFilterIndexVue from "@/views/app/administrator/reports/outgoing-invoices/list/form/filter.vue";
import LoadingIndexVue from "@/components/loading.vue";

// Services
import {getRequest} from "@/services/api/request";
import {getDateFormat, getMonthFirstDay, getMonthLastDay} from "@/services/app/date";
import {getInformation, getCurrencyFormatHRK, getCurrencyFormatEUR, getInvoiceStatusShort, getInvoiceStatusClass, getInvoiceNumberInYear} from "@/services/app/data";
import {getTranslation} from "@/services/app/translation";

@Options({
  components: {
    ElTable,
    ElTableColumn,
    ElButton,
    Printer,
    Vue3Html2pdf,
    AppAdministratorReportsOutgoingInvoicesListFormFilterIndexVue,
    LoadingIndexVue,
  },
})
export default class AppAdministratorInvoicesOutgoingInvoicesListIndexVue extends Vue {
  isLoading = false;
  isLoadingProgress = false;

  progress = 0;

  partner_id = "*";
  status = "*";
  period = [
    getMonthFirstDay(),
    getMonthLastDay(),
  ];
  sort = ["invoice-number-in-year"];
  numberOfPages = 1;
  statistic: { amount: number, paidAmount: number, difference: number} = {
    amount: 0,
    paidAmount: 0,
    difference: 0,
  }

  outgoingInvoices: Array<AppOutgoingInvoiceIndex> = [];
  partners: Array<AppPartnerIndex> = [];

  get isDataLoaded(): boolean {
    return this.outgoingInvoices.length > 0 && this.isLoading === false;
  }

  get isDataLoadedAndEmpty(): boolean {
    return this.outgoingInvoices.length === 0 && this.isLoading === false;
  }

  getDateFormat(data: string): string {
    return getDateFormat(data);
  }

  getInformation(data: string): string {
    return getInformation(data);
  }

  getCurrencyFormatHRK(data: string): string {
    return getCurrencyFormatHRK(data);
  }

  getCurrencyFormatEUR(data: string): string {
    return getCurrencyFormatEUR(data);
  }

  getInvoiceStatusShort(data: string): string {
    return getInvoiceStatusShort(data);
  }

  getInvoiceStatusClass(data: string): string {
    return getInvoiceStatusClass(data);
  }

  getInvoiceNumberInYear(number: string, year: string): string {
    return getInvoiceNumberInYear(number, year);
  }

  get translation(): any {
    return getTranslation([
      "amountWithVAT",
      "date",
      "dateOfExchangeRate",
      "dateOfExchangeRateShort",
      "invoiceNumber",
      "invoiceNumberInYear",
      "invoiceNumberInYearIRA",
      "noData",
      "of",
      "paid",
      "page",
      "partner",
      "paymentDescription",
      "report",
      "status",
    ]);
  }

  $refs!: {
    html2Pdf: any;
  };

  async getOutgoingInvoicesReport(partner_id = "*", status = "*", period = [getMonthFirstDay(), getMonthLastDay()], sort = ["invoice-number-in-year"]): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/common/outgoing-invoices/report`,
      formData: {
        organization_id: store.getters.getOrganizationMember.organization_id,
        partner_id: this.partner_id = partner_id,
        status: this.status = status,
        period: this.period = period,
        sort: this.sort = sort,
        pagination: false,
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.outgoingInvoices = r.data.data;
        this.setSorting();
        this.statistic = r.data.statistic;
        this.numberOfPages = Math.ceil(this.outgoingInvoices.length / 18);
      }
    });
    this.isLoading = false;
  }

  async getPartners(): Promise<void> {
    await getRequest({
      uri: `/common/partners`,
      formData: {
        organization_id: store.getters.getOrganizationMember.organization_id,
        pagination: false,
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.partners = r.data;
      }
    });
  }

  getReport(): void {
    this.$refs.html2Pdf.generatePdf();
  }

  setSortByInvoiceNumberInYear(): void {
    this.outgoingInvoices = this.outgoingInvoices.sort((a, b) => {
      return parseInt(b.content.invoiceNumberInYear) - parseInt(a.content.invoiceNumberInYear);
    });

    this.outgoingInvoices = this.outgoingInvoices.sort((a, b) => {
      return parseInt(b.content.year) - parseInt(a.content.year);
    });
  }

  setSortByDate(): void {
    this.outgoingInvoices = this.outgoingInvoices.sort((a, b) => {
      return moment.utc(b.content.date).diff(moment.utc(a.content.date));
    });
  }

  setSortByPartner(): void {
    this.outgoingInvoices = this.outgoingInvoices.sort(function (a, b) {
      if (a.partner.content.title < b.partner.content.title) {
        return -1;
      }
      if (a.partner.content.title > b.partner.content.title) {
        return 1;
      }
      return 0;
    });
  }

  setSorting(): void {
    if (this.sort.includes("invoice-number-in-year") && this.sort.includes("date") && this.sort.includes("partner")) {
      this.setSortByInvoiceNumberInYear();
      this.setSortByDate();
      this.setSortByPartner();

      return;
    }

    if (this.sort.includes("invoice-number-in-year") && this.sort.includes("date")) {
      this.setSortByInvoiceNumberInYear();
      this.setSortByDate();

      return;
    }

    if (this.sort.includes("invoice-number-in-year") && this.sort.includes("partner")) {
      this.setSortByInvoiceNumberInYear();
      this.setSortByPartner();

      return;
    }

    if (this.sort.includes("invoice-number-in-year")) {
      this.setSortByInvoiceNumberInYear();

      return;
    }

    if (this.sort.includes("date") && this.sort.includes("partner")) {
      this.setSortByDate();
      this.setSortByPartner();

      return;
    }

    if (this.sort.includes("date")) {
      this.setSortByDate();

      return;
    }

    if (this.sort.includes("partner")) {
      this.setSortByPartner();

      return;
    }

    this.setSortByInvoiceNumberInYear();
  }

  setProgress(e: number): void {
    if (e < 100) {
      this.isLoadingProgress = true;
      this.progress = e;
    } else {
      this.isLoadingProgress = false;
      this.progress = 0;
    }
  }

  async created(): Promise<void> {
    await this.getOutgoingInvoicesReport();
    await this.getPartners();
  }
}
